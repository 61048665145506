<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <form class="touchpoints-edition" @submit.prevent="onSubmit" novalidate autocomplete="off" v-else>
      <UiBanner large>
        <template v-slot:content>
          <div v-if="currentTouchpoint.type === 'product'" class="tw-flex tw-items-center">
            <v-chip class="tw-font-mono" dark>{{ currentTouchpoint.product.sku }}</v-chip>
            <div class="tw-ml-4">
              <UiTitle large>
                <span>{{ currentTouchpoint.product.name }}</span>
              </UiTitle>
              <UiSubtitle
                ><span>{{
                  `${$t('touchpoints.edition.action.label', {
                    updateDate: timeFromNow(currentTouchpoint.lastLpUpdate, 'L LT'),
                  })} `
                }}</span
                ><a @click="refreshLp">{{ $t('touchpoints.edition.action.lpRefresh') }}</a></UiSubtitle
              >
            </div>
          </div>
          <UiTitle v-else-if="currentTouchpoint.type === 'custom'" large :title="currentTouchpoint.description" />
        </template>
      </UiBanner>
      <UiContainer large with-actions>
        <section v-if="currentTouchpoint.type === 'product'">
          <UiTitle title="Produit" small />
          <div class="tw-flex tw-gap-24 tw-mt-2">
            <div>
              <UiSubtitle :subtitle="$t('touchpoints.edition.fields.product.sku')" />
              <div>
                {{ currentTouchpoint.product.sku }}
              </div>
            </div>
            <div>
              <UiSubtitle :subtitle="$t('touchpoints.edition.fields.product.name')" />
              <div>
                {{ currentTouchpoint.product.name }}
              </div>
            </div>
          </div>
        </section>
        <section class="required-asterisk" v-if="currentTouchpoint.type === 'custom'">
          <UiTitle :title="$t('touchpoints.edition.fields.destination.title')" small />
          <UiSubtitle class="tw-mb-2" :subtitle="$t('touchpoints.edition.fields.destination.subtitle')" />
          <v-text-field
            v-model="touchpointModel.url.destination"
            :error-messages="urlDestinationErrors"
            type="url"
            solo
            flat
            outlined
            :placeholder="$t('touchpoints.edition.fields.destination.placeholder')"
            :prepend-inner-icon="icons.mdiBullseyeArrow"
          />
        </section>
        <section class="required-asterisk" :class="{ 'tw-mt-6': currentTouchpoint.type === 'product' }">
          <UiTitle :title="$t('touchpoints.edition.fields.shortURL.title')" small />
          <UiSubtitle class="tw-mb-2" :subtitle="$t('touchpoints.edition.fields.shortURL.subtitle')" />
          <v-text-field
            v-model="touchpointModel.url.source"
            :error-messages="urlSourceErrors"
            type="url"
            solo
            flat
            outlined
            :placeholder="$t('touchpoints.edition.fields.shortURL.placeholder')"
            :prepend-inner-icon="icons.mdiQrcode"
            disabled
            readonly
            hide-details
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    :ripple="false"
                    v-on="on"
                    target="_blank"
                    :href="currentTouchpoint.url.source"
                    class="tw--mr-2"
                  >
                    <v-icon> {{ icons.mdiOpenInNew }} </v-icon>
                  </v-btn>
                </template>
                {{ $t('label.newTab') }}
              </v-tooltip>
            </template>
          </v-text-field>
          <div class="tw-mt-3 tw-mb-3" v-if="touchpointModel.url.additionalSuffixes?.length > 0">
            <div>{{ $t('touchpoints.creation.custom.form.field.shortUrl.additionalSuffixes') }}:</div>
            <v-chip
              v-for="suffixe in touchpointModel.url.additionalSuffixes"
              :key="suffixe"
              class="tw-font-mono tw-mr-2 tw-mt-2"
              grey
            >
              /{{ suffixe }}
            </v-chip>
          </div>
        </section>
        <section class="required-asterisk" v-if="currentTouchpoint.type === 'custom'">
          <UiTitle :title="$t('touchpoints.edition.fields.description.title')" small />
          <UiSubtitle class="tw-mb-2" :subtitle="$t('touchpoints.edition.fields.description.subtitle')" />
          <v-text-field
            v-model="touchpointModel.description"
            :error-messages="descriptionErrors"
            solo
            flat
            outlined
            :placeholder="$t('touchpoints.edition.fields.description.placeholder')"
            :prepend-inner-icon="icons.mdiNotebookEditOutline"
          />
        </section>
        <section class="tw-mt-3">
          <UiTitle :title="$t('touchpoints.edition.fields.quantity.title')" small />
          <UiSubtitle class="tw-text-sm tw-mb-2">
            <span>{{ $t('touchpoints.edition.fields.quantity.subtitle') }}</span>
            <span
              ><router-link :to="{ name: 'TouchpointsImport' }">{{
                $t('touchpoints.edition.fields.quantity.import.link')
              }}</router-link></span
            >
          </UiSubtitle>
          <v-text-field
            v-model.number="touchpointModel.quantity"
            :error-messages="quantityErrors"
            type="number"
            :min="0"
            solo
            flat
            outlined
            :placeholder="$t('touchpoints.edition.fields.quantity.placeholder')"
            :prepend-inner-icon="icons.mdiPrinterOutline"
            @input="checkEmptyQuantity"
          />
        </section>
        <section class="tw-mb-6 tw-flex tw-flex-col tw-items-center">
          <UiSubtitle>
            <a @click="refreshQr">{{ $t('touchpoints.edition.action.qrRefresh') }}</a>
          </UiSubtitle>
          <img :src="touchpointModel.qrCodeUrl.svgUrl" alt="QR Code" width="200" class="tw-rounded-lg" />
          <div class="tw-flex tw-gap-8 tw-mt-2">
            <a :href="touchpointModel.qrCodeUrl.pngUrl" target="_blank">PNG</a>
            <a :href="touchpointModel.qrCodeUrl.svgUrl" target="_blank">SVG</a>
          </div>
        </section>
        <UiActions large centered>
          <v-btn rounded text @click="$router.push({ name: 'TouchpointsList' })">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
            {{ $t('button.save') }}
          </v-btn>
        </UiActions>
      </UiContainer>
    </form>
  </v-fade-transition>
</template>

<script>
import { timeFromNow } from '@/utils/date.util'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, url, minValue } from 'vuelidate/lib/validators'
import { mdiBullseyeArrow, mdiQrcode, mdiNotebookEditOutline, mdiPrinterOutline, mdiOpenInNew } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { clone } from '@/utils/utilities.util'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiActions from '@/components/UI/Actions.vue'

export default {
  name: 'TouchpointsEdition',
  components: {
    UiBanner,
    UiContainer,
    UiTitle,
    UiSubtitle,
    UiActions,
  },
  mixins: [validationMixin],
  async created() {
    this.setUpdating(true)
    await this.getTouchpoint(this.$route.params.id)
    this.touchpointModel = clone(this.currentTouchpoint)
    this.setUpdating(false)
  },
  data: () => ({
    icons: {
      mdiBullseyeArrow,
      mdiQrcode,
      mdiNotebookEditOutline,
      mdiPrinterOutline,
      mdiOpenInNew,
    },
    timeFromNow,
    lpRefreshing: false,
    qrRefreshing: false,
    saving: false,
    touchpointModel: null,
  }),
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      currentTouchpoint: state => state.touchpoints.currentTouchpoint,
      working: state => state.backoffice.working,
    }),
    descriptionErrors() {
      const errors = []
      if (!this.$v.touchpointModel.description.$dirty) return errors
      !this.$v.touchpointModel.description.required && errors.push(this.$t('error.required'))
      return errors
    },
    quantityErrors() {
      const errors = []
      if (!this.$v.touchpointModel.quantity.$dirty) return errors
      !this.$v.touchpointModel.quantity.minValue &&
        errors.push(
          this.$t('error.minValue', {
            minValue: this.$v.touchpointModel.quantity.$params.minValue.min,
          })
        )
      return errors
    },
    urlSourceErrors() {
      const errors = []
      if (!this.$v.touchpointModel.url.source.$dirty) return errors
      !this.$v.touchpointModel.url.source.required && errors.push(this.$t('error.required'))
      !this.$v.touchpointModel.url.source.url && errors.push(this.$t('error.url'))
      return errors
    },
    urlDestinationErrors() {
      const errors = []
      if (!this.$v.touchpointModel.url.destination.$dirty) return errors
      !this.$v.touchpointModel.url.destination.required && errors.push(this.$t('error.required'))
      !this.$v.touchpointModel.url.destination.url && errors.push(this.$t('error.url'))
      return errors
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      getTouchpoint: 'touchpoints/getTouchpoint',
      updateTouchpoint: 'touchpoints/updateTouchpoint',
      refreshLP: 'touchpoints/refreshLP',
      refreshQR: 'touchpoints/refreshQR',
      setAlert: 'backoffice/setAlert',
    }),
    checkEmptyQuantity(value) {
      if (value === '') this.touchpointModel.quantity = null
    },
    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        try {
          await this.updateTouchpoint(this.touchpointModel)
          this.setAlert({
            color: 'success',
            text: this.$t('touchpoints.edition.notification.success'),
          })
          this.touchpointModel = clone(this.currentTouchpoint)
        } catch {
          this.setAlert({
            color: 'error',
            text: this.$t('error.notification.default'),
          })
        }
        this.saving = false
      }
    },
    async refreshLp() {
      this.lpRefreshing = true
      await this.refreshLP({
        _ids: [this.currentTouchpoint._id],
        search: [],
        filters: [],
      })
      this.lpRefreshing = false
      await this.getTouchpoint(this.$route.params.id)
    },
    async refreshQr() {
      this.qrRefreshing = true
      await this.refreshQR({
        _ids: [this.currentTouchpoint._id],
        search: [],
        filters: [],
      })
      this.qrRefreshing = false
      await this.getTouchpoint(this.$route.params.id)
    },
  },
  validations() {
    return {
      touchpointModel: {
        quantity: {
          minValue: minValue(0),
        },
        description: {
          required: requiredIf(() => {
            return this.currentTouchpoint.type === 'custom'
          }),
        },
        url: {
          destination: {
            required: requiredIf(() => {
              return this.currentTouchpoint.type === 'custom'
            }),
            url,
          },
          source: {
            required,
            url,
          },
        },
      },
    }
  },
}
</script>
